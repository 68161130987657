export default [
    {
        type: "selection",
        width: 38,
        align: "center",
    },

    {
        title: 'Subject',
        key: 'subject',
        minWidth: 80,
    },

    {
        title: 'Created Date',
        key: 'datecreated',
        minWidth: 80,
    },
]
